import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { saveFormEntry, getFormEntry, resetFormData } from '../../data/formEntry/actions';

import FormWizard from '../../components/FormWizard';
import ZipCode from '../../components/ZipCode';
import SelectVehicleYear from '../../components/SelectVehicleYear';
import SelectVehicleMake from '../../components/SelectVehicleMake';
import SelectVehicleModel from '../../components/SelectVehicleModel';
import VehicleOwner from '../../components/VehicleOwner';
import DriversLicenseStatus from '../../components/DriversLicenseStatus';
import CurrentlyInsured from '../../components/CurrentlyInsured';
import CurrentCarrier from '../../components/CurrentCarrier';
import PolicyDuration from '../../components/PolicyDuration';
import Gender from '../../components/Gender';
import Married from '../../components/Married';
import CreditScore from '../../components/CreditScore';
import Residence from '../../components/Residence';
import Record from '../../components/Record';
import BirthMonth from '../../components/BirthMonth';
import BirthDay from '../../components/BirthDay';
import BirthYear from '../../components/BirthYear';
import GetMyQuote from '../../components/GetMyQuote';


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


const quoteForm = props => {
	// const { stepNumber } = props.match.params;
	const { formEntry, saveEntry, getEntry, history, resetFormData } = props;
	const jornayaRef = React.createRef();

	return (
		<Fragment>
			<FormWizard
				initialValues={formEntry}
				onSubmit={async values =>
		        	sleep(300).then(() => console.log("submit"))
		      	}
		      	initialStepNumber={formEntry.currentStep}
		      	prevSteps={formEntry.prevSteps}
		      	history={history}
		      	incrementStep={formEntry.incrementStep}
		      	saveEntry={saveEntry}
		      	getEntry={getEntry}
		      	resetFormData={resetFormData}
		      	jornayaRef={jornayaRef}
		    >

		    	<ZipCode />
		    	<SelectVehicleYear vehicleNumber={1} />
		    	<SelectVehicleMake vehicleNumber={1} />
		    	<SelectVehicleModel vehicleNumber={1} />

		    	{/*
		    	<AddSecondVehicle />
		    	<SelectVehicleYear vehicleNumber={2} />
		    	<SelectVehicleMake vehicleNumber={2} />
		    	<SelectVehicleModel vehicleNumber={2} />
		    	*/}

		    	<VehicleOwner />
		    	<DriversLicenseStatus />
		    	<CurrentlyInsured />
		    	<CurrentCarrier />
		    	<PolicyDuration />

		    	<Gender />
		    	<Married />
		    	<CreditScore />
		    	<Residence />
		    	<Record />
		    	<BirthMonth />
		    	<BirthDay />
		    	<BirthYear />
		    	<GetMyQuote />
		    </FormWizard>
		    <input
		    	ref={jornayaRef}
				id="leadid_token"
				name="universal_leadid"
				type="hidden"
				value=""
			/>
		</Fragment>
	)
};

const mapStateToProps = state => {
	const { formEntry } = state.data;

	return {
		formEntry
	}
}

const mapDispatchToProps = dispatch => {
	return {
		saveEntry: (formData, currentStep, prevStep, completed) => dispatch(saveFormEntry(formData, currentStep, prevStep, completed)),
		getEntry: (id) => dispatch(getFormEntry(id)),
		resetFormData: () => dispatch(resetFormData())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(quoteForm));


import React, { Fragment, useState, useEffect, cloneElement, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Form, Formik } from "formik";
import ReactPixel from 'react-facebook-pixel';

import getPropertyValue from '../../utils/getPropertyValue';
import Steps from '../../components/Steps';
import Loading from '../../components/Loading';
import WelcomeBack from '../../components/WelcomeBack';
import {SiteContext} from "../../siteContext";

const FormWizard = (props) => {
	const {
		children,
		initialValues,
		onSubmit,
		initialStepNumber,
		prevSteps: savedPreviousSteps,
		history,
		saveEntry,
		getEntry,
		resetFormData,
		jornayaRef
	} = props;

	const siteContext = useContext(SiteContext);
	const { fbPixelId } = siteContext;

	const [cookies, setCookie, removeCookie] = useCookies(['quotegenius']);

	const [stepNumber, setStepNumber] = useState(initialStepNumber ? initialStepNumber - 1 : 0);
	const [prevSteps, setPrevSteps] = useState(savedPreviousSteps ? savedPreviousSteps : [0, ]);
	const [validationSchema, setValidationSchema] = useState({});
	const [showWelcomeBack, setShowWelcomeBack] = useState(false);
	const [formResetting, setFormResetting] = useState(false);

	const [ready, setReady] = useState(false);

	const steps = React.Children.toArray(children);
	const [snapshot, setSnapshot] = useState(initialValues);

	const step = steps[stepNumber];
	const totalSteps = steps.length;
	const isLastStep = stepNumber === totalSteps - 1;

	// Move to next form Step
	const next = (values, setFieldValue) => {
		const { incrementStep } = values;
		const newStep = Math.min(stepNumber + incrementStep, totalSteps - 1);
		const stepHistory = prevSteps;

		setSnapshot(values);

		// Keep a history of previous steps
		stepHistory.push(stepNumber);
		setPrevSteps(stepHistory);

		setShowWelcomeBack(false);

		setStepNumber(newStep);
		history.push(`/${newStep + 1}`);
		setFieldValue('incrementStep', 1);
	};

	// Allow users to move back through step stack
	const previous = values => {
		const stepHistory = prevSteps;
		const prevStep = stepHistory.pop();
		const nextStep = stepNumber + 1;

		setShowWelcomeBack(false);
		setPrevSteps(stepHistory);
		setSnapshot(values);
		setStepNumber(prevStep);

		saveEntry(values, nextStep, prevSteps);

		history.push(`/${prevStep + 1}`);
	};

	// Handle submit based on form location
	const handleSubmit = async (values, bag) => {
		const { setFieldValue } = bag;

		if (step.props.onSubmit) {
			await step.props.onSubmit(values, bag);
		}

		if (stepNumber === 0) {
			window.snaptr('track','ZIP');

			window.pintrk('track', 'signup', {
				lead_type: 'Zipcode completion'
			});
		}

		if (isLastStep) {
			saveEntry(values, stepNumber, prevSteps, true);
			removeCookie('quotegenius');
			history.push('/results');

			// Last step - Facebook Pixel for Affid 4370
			if (initialValues.affid && initialValues.affid === '4370') {

				// Initialize additional pixels
				ReactPixel.init('587831175223643');
				ReactPixel.init('3206067416156576');
				ReactPixel.init('2082545491909779');

				// Below tracking will be sent to all initialized pixel inc Aragon's
				// This may need to be changed by using trackSingle(PixelId, event, data)
				// see: https://github.com/zsajjad/react-facebook-pixel
				ReactPixel.pageView();
				ReactPixel.track('Lead');
				ReactPixel.track('SubmitApplication');
				ReactPixel.track('CompleteRegistration');

			}

			return onSubmit(values, bag);
		} else {
				bag.setTouched({});
				const nextStep = stepNumber + 1;

				next(values, setFieldValue);
				saveEntry(values, nextStep, prevSteps);
		}
	};

	const onButtonClick = (question, value) => {
		// GA Event tracking
		// ReactGA.event({
		// 	action: 'lead form click',
		// 	category: question,
		// 	label: String(value)
		// });

		window.gtag('event', 'lead form click', {
		  'event_category': question,
		  'event_label': value
		});

		// Facebook Event
		ReactPixel.trackSingleCustom(fbPixelId, question, {answer: String(value)});
	};

	// If session ID changes in data store create or update the cookie
	useEffect(() => {
		if(initialValues.sessionId) {
			setCookie(
				'quotegenius',
				initialValues.sessionId,
				{
					path: '/',
					maxAge: 604800
				}
			);

			setSnapshot(initialValues);

			// Navigate to where they finished
			if (initialStepNumber > stepNumber) {
				setStepNumber(initialStepNumber -1);
			}
		}
	}, [initialValues.sessionId]);


	// Component did mount - Check for cookie
	useEffect(() => {
		const fetchEntry = async () => {
			const { quotegenius } = cookies;

			if (quotegenius && !initialValues.sessionId) {
				await getEntry(quotegenius);
				setShowWelcomeBack(true);
			}

			setReady(true);

		}

		fetchEntry();

	}, []);

	// Start Again
	const startAgain = () => {
		setReady(false);
		setFormResetting(true);
		removeCookie('quotegenius');
		resetFormData();
		setShowWelcomeBack(false);
		setStepNumber(0);
		history.push('/');
	}

	// Reset the form state after Data Store resets
	useEffect(() => {
		if (!initialValues.sessionId && formResetting) {
			setFormResetting(false);
			setSnapshot(initialValues);
			setReady(true);
		}

	}, [initialValues.sessionId])

	return (
		<Fragment>
			{ready
				? 	<Formik
						initialValues={snapshot}
						onSubmit={handleSubmit}
						validationSchema={validationSchema}
					>

						{formik => {
							const newProps = formik;
							const { touched, errors } = formik;

							const getFieldError = (fieldName) => {
								let error = false;

								const fieldError = getPropertyValue(errors, fieldName);
								const fieldToched = getPropertyValue(touched, fieldName);

								if (fieldError && fieldToched) {
									error = fieldError;
								}

								return error;
							};

							newProps['setValidationSchema'] = setValidationSchema;
							newProps['getFieldError'] = getFieldError;
							newProps['onButtonClick'] = onButtonClick;
							newProps['jornayaRef'] = jornayaRef;
							newProps['goBack'] = previous;

							return(
								<Form>
									{showWelcomeBack && <WelcomeBack startAgain={startAgain} />}
									<Steps goBack={previous} stepNumber={stepNumber} values={formik.values} />
									{ cloneElement(step, newProps) }
								</Form>
							)
						}}

					</Formik>

			: <Loading />
			}
		</Fragment>
	);
}

export default FormWizard;

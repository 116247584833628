import React from 'react';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';


const AdditionalProviders = (props) => {
    const {
        additionalProviders,
        offerId
    } = props;

    const iframeRef = React.createRef();

    const getBodyMarkup = markup => {
        return {__html: markup};
    };

    const onProviderClick = (transactionId, amount, url) => {
        const eventId = parseInt(offerId, 10)  === 39391 ? 21848 : 21797;
        const iframe = `<iframe src="https://www.opptmzr.com/?nid=210&event_id=${eventId}&oid=${offerId}&transaction_id=${transactionId}&amount=${amount}" scrolling="no" frameborder="0" width="1" height="1"></iframe>`;
        // iframeRef.innerHTML(iframe);
        iframeRef.current.innerHTML = iframe;
        window.open(url, '_blank');

    }

    const renderProviders = () => {
        return additionalProviders.data.sort((a, b) => {
            return parseFloat(b.cpc) - parseFloat(a.cpc);
        }).map((item, index) => {
            return (
                <div
                    key={`${item.client_id}-${index}`}
                    className="row mt-1 mb-4 mb-sm-2 d-flex align-items-center"
                    styleName="provider"
                >
                    <div className="col-sm-3 col-8 mx-auto">
                        <a onClick={ () => onProviderClick(item.client_id, item.cpc, item.click_url)}
                           rel="noopener noreferrer"
                        >
                            <img src={item.image_url} styleName="provider__image" alt={item.headline}/>
                        </a>
                    </div>

                    <div className="col-sm-5 text-center text-sm-left">
                        <a
                            onClick={ () => onProviderClick(item.client_id, item.cpc, item.click_url)}
                            className=""
                            rel="noopener noreferrer"
                        >
                            <h5 styleName="provider__title">{item.headline}</h5>
                            <div className="d-none" dangerouslySetInnerHTML={getBodyMarkup(item.body)}/>
                        </a>
                    </div>

                    <div className="col-sm-4 text-center">
                        <a onClick={ () => onProviderClick(item.client_id, item.cpc, item.click_url)}
                           rel="noopener noreferrer"
                           className="btn btn-success text-white"
                        >
                            View My Quote
                        </a>
                    </div>
                </div>
            );
        });
    }

    return (
	   <div className="row">
        <div className="col-12 card p-4 mx-auto">
            {renderProviders()}
            <div ref={iframeRef}/>
        </div>
	   </div>
    );
};

export default CSSModules(AdditionalProviders, styles);


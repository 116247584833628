import React from 'react';
import moment from 'moment';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const months = [
	'January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December'
];

const BirthMonth = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
	} = props;

	const renderMonths = () => {
		return months.map((month, index) => {
			const monthNumber = index + 1;
			const momentMonth = moment(monthNumber, 'M');

			return (
				<ChoiceButton
					key={month}
					values={values}
					className="btn btn-list-select no-width"
					name="dob.month"
					value={momentMonth.format('MM')}
					setFieldValue={setFieldValue}
					onButtonClick={onButtonClick}
					type="submit"
				>
					{month}
				</ChoiceButton>
			);
		});
	};

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-md-10 col-lg-8">
					<h3>Birth Month</h3>
					<div className="row">
						<div className="col-12">
							{renderMonths()}
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default BirthMonth;
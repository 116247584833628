import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const CreditScore = props => {
	const {
		setFieldValue,
		values,
		onButtonClick
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-md-8">
					<h3>Credit Score</h3>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="credit_score"
								value="Average"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Not Sure (that's okay)
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="credit_score"
								value="Poor"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Poor (below 580)
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="credit_score"
								value="Average"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Fair/Average (580-679)
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="credit_score"
								value="Good"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Good (680-719)
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="credit_score"
								value="Excellent"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Excellent (720+)
							</ChoiceButton>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default CreditScore;
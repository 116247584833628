import {
    RECEIVE_ADDITIONAL_PROVIDERS,
    REQUEST_ADDITIONAL_PROVIDERS
} from './actions';


const initialState = {
    requesting: false,
    data: []
};


export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ADDITIONAL_PROVIDERS:
            return Object.assign({}, state, {
                requesting: true,
                data: []
            });
        case RECEIVE_ADDITIONAL_PROVIDERS:
            return Object.assign({}, state, {
                requesting: false,
                data: action.data
            });
        default:
            return state;
    }
};

import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const Gender = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-10">
					<h3>Gender</h3>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="gender"
								value="Female"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Female
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="gender"
								value="Male"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Male
							</ChoiceButton>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default Gender;
import baseApi from '../api';

export default class vehicleAPI extends baseApi {
	async getVehicleModels(make, year) {
		const data = {
			action: 'getModels',
			make,
			year
		};

		const response = await this.fetchFromAPI(
			'',
			'request-vehicle-models',
			'Request Vehicle Models',
			'post',
			data
		);

		return response;
	}
}
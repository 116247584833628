import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const VehicleOwner = props => {
	const {
		setFieldValue,
		values,
		onButtonClick
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12">
					<h3>Do you own or lease your vehicle?</h3>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="owner"
								value="Owned"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Own
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="owner"
								value="Leased"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Lease
							</ChoiceButton>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default VehicleOwner;
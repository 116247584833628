import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';

const makes = [
	"ACURA","AUDI","BMW","BUICK","CADILLAC","CHEVROLET","FORD","DODGE","CHRYSLER","GMC","HONDA","HYUNDAI","INFINITI",
	"JAGUAR","JEEP","KIA","LAMBORGHINI","LAND ROVER","LEXUS","LINCOLN","MAZDA","MCLAREN",
	"MERCEDES-BENZ","MITSUBISHI","NISSAN","SUBARU","TESLA","TOYOTA","VOLKSWAGEN","VOLVO","OTHER"
];

const SelectVehicleMake = props => {
	const {
		setFieldValue,
		vehicleNumber,
		values,
		onButtonClick
	} = props;

	const onClick = (make) => {
		if (make === 'OTHER') {
			setFieldValue(`vehicle_${vehicleNumber}_make`, 'OTHER');
			setFieldValue(`vehicle_${vehicleNumber}_model`, 'OTHER');
			setFieldValue('incrementStep', 2);
		}
	}

	const renderMakes = () => {
		return makes.map((make) => {
			return (
				<ChoiceButton
					key={make}
					values={values}
					className="btn btn-list-select no-width"
					name={`vehicle_${vehicleNumber}_make`}
					value={make}
					setFieldValue={setFieldValue}
					onButtonClick={onButtonClick}
					onClick={() => onClick(make)}
				>
					{make}
				</ChoiceButton>
			);
		});
	};

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-lg-10">
					<h3>Select Your Vehicle</h3>
					<label htmlFor="vehicleYear">Make</label>
					<div className="row">
						<div className="col">
							{renderMakes()}
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default SelectVehicleMake;
import React, { useEffect } from 'react';
import * as Yup from "yup";
import geoip2 from 'geoip2';
import BootstrapField from '../BootstrapField';
import EF from 'EF';

import WizardStep from '../WizardStep';


const ZipCode = props => {
	const {
		setValidationSchema,
		getFieldError,
		values,
		setFieldValue,
		onButtonClick
	} = props;

	const validationSchema = Yup.object({
        zip_code: Yup.string()
        .matches(/^[0-9]{5}$/, "A Valid Zip Code is required.")
        .required("A Valid Zip Code is required."),
    });

    const getZipCode = () => {
    	const onSuccess = (geoipResponse) => {
    		const { postal: { code: zipCode } } = geoipResponse;

    		if (!values.zip_code) {
    			setFieldValue('zip_code', zipCode);
    		}
    	};

    	const onError = (errors) => {
    		return;
    	}

    	geoip2.city(onSuccess, onError);
    };

	useEffect(() => {
  		setValidationSchema(validationSchema);

  		getZipCode();

  		const offerId = EF.urlParameter('oid');
  		const affiliateId = EF.urlParameter('affid');
  		const sub1 = EF.urlParameter('sub1');
  		const sub2 = EF.urlParameter('sub2');
  		const sub3 = EF.urlParameter('sub3');
  		const sub4 = EF.urlParameter('sub4');
  		const sub5 = EF.urlParameter('sub5');
  		const uid = EF.urlParameter('uid');

  		EF.click({
			offer_id: offerId,
			affiliate_id: affiliateId,
			sub1: sub1,
			sub2: sub2,
			sub3: sub3,
			sub4: sub4,
			sub5: sub5,
			uid: uid,
		});

		setFieldValue('affid', affiliateId);
		setFieldValue('oid', offerId);
		setFieldValue('lp_s1', sub1);
		setFieldValue('lp_s2', sub2);
		setFieldValue('lp_s3', sub3);
		setFieldValue('lp_s4', sub4);
		setFieldValue('lp_s5', sub5);

  	}, []);

	return (
		<WizardStep>
			<div className="row">
			<div className="col-12 mx-auto alert alert-info text-center">
				<h3>Paying too much for your car insurance?</h3>
				<p>Thousands of Drivers Just Like You are Saving Hundreds of $$$ on Car Insurance by Using This Tool!</p>
			</div>
			</div>
			<div className="question row">
				<div className="col-12 col-lg-6">
					<h3>Enter Your Zip Code To Begin</h3>
					<label htmlFor="zip_code">Zip Code</label>
					<div className="input-group">
						<BootstrapField
							type="text"
							className="form-control custom-form-control"
							name="zip_code"
							max="5" min="5"
							placeholder="#####"
							hasErrors={getFieldError('zip_code')}
						/>
						<div className="input-group-append">
							<button
								type="submit"
								className="btn btn-success"
								onClick={() => onButtonClick('zip_code', values['zip_code'])}
								>
									Get Rates
								</button>
						</div>
						<div className="invalid-feedback">{ getFieldError('zip_code') }</div>
				   	</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default ZipCode;

import API from "./api";

export const REQUEST_VEHICLE_MODELS = 'REQUEST_VEHICLE_MODELS';
export const RECEIVE_VEHICLE_MODELS = 'RECEIVE_VEHICLE_MODELS';

const requestingVehicleModels = () => {
	return {
		type: REQUEST_VEHICLE_MODELS
	}
}

const receiveVehicleModels = (modelData) => {
	const models = modelData.Results.map((model) => {
		return model['Model_Name'];
	});

	return {
		type: RECEIVE_VEHICLE_MODELS,
		models
	}
}

function getVehicleModels(make, year) {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requestingVehicleModels());

		const json = await dataAPI.getVehicleModels(make, year);

		dispatch(receiveVehicleModels(json.data));
	}
}

export { getVehicleModels };
import React from 'react';
import moment from 'moment';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const BirthDay = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
	} = props;

	const renderDays = () => {
		const days = [];
		const { month } = values.dob;
		const thirtyDays = [9, 4, 6, 11];
		let count = 31;

		if (thirtyDays.includes(parseInt(month, 10))) {
			count = 30;
		} else if (parseInt(month, 10) === 2) {
			count = 29
		}

		for (let i = 1; i <= count; i++) {
			days.push(i);
		}

		return days.map((day) => {
			let momentDay = moment(`${day} 12`, 'D MM');

			return (
				<ChoiceButton
					key={day}
					values={values}
					className="btn btn-list-select"
					name="dob.day"
					value={momentDay.format('DD')}
					setFieldValue={setFieldValue}
					onButtonClick={onButtonClick}
					type="submit"
				>
					{momentDay.format('DD')}
				</ChoiceButton>
			);
		});
	};

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-md-10 col-lg-8">
					<h3>Birth Day</h3>
					<div className="row">
						<div className="col-12">
							{renderDays()}
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default BirthDay;
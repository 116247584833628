import React from 'react';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';


const WelcomeBack = (props) => {
	const { startAgain } = props;

	return (
		<div className="pt-3">
			<div className="alert alert-success text-center">
				<h4>Welcome back!</h4>
				<p>
					You can continue where you left off or <button className="btn-link" styleName="link" onClick={() => startAgain()}>click here to start again</button>.
					</p>
			</div>
		</div>
	);
};

export default CSSModules(WelcomeBack, styles);

import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const DriversLicenseStatus = props => {
	const {
		setFieldValue,
		values,
		onButtonClick
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-md-10">
					<h3>What is the status of your drivers license?</h3>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Active"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Active
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Suspended"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Suspended
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Probation"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Probation
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Restricted"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Restricted
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Learner"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Learner
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="Temporary"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Temporary
							</ChoiceButton>
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="DL_status"
								value="International"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								International
							</ChoiceButton>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default DriversLicenseStatus ;
const featuredInsuranceCompanies = [
"21st Century Insurance",
"Allstate",
"Country Insurance",
"Esurance",
"Farmers",
"GEICO",
"Liberty Mutual",
"Mercury",
"Nationwide",
"New York Life",
"Progressive",
"State Farm",
];

const otherInsuranceCompanies = [
"AAA",
"AETNA",
"AFLAC",
"AIG / American General",
"American Benefit Planners",
"American Family",
"American Insurance",
"American National",
"American Republic",
"American Savers Plan",
"AmeriPlan",
"AMG",
"Amica",
"Answer Financial",
"Anthem",
"Arbella",
"Assurant",
"Atlantis",
"Auto Club Insurance Company",
"AXA Advisors",
"Bankers Life and Casualty",
"Best Agency USA",
"Blue Cross / Blue Shield",
"Brightway",
"Christian Fidelity",
"Citizens",
"Cotton States",
"Countrywide Insurance",
"CSE Insurance Group",
"Direct General",
"eFinancial",
"eHealthInsurance",
"Electric",
"Elephant",
"Erie",
"Farm Bureau/Farm Family/Rural",
"Farmers Union",
"First Family Insurance",
"Foresters",
"Freeway Insurance Services",
"GMAC/National General",
"Golden Rule",
"Grange",
"Great American",
"Health Benefits Direct",
"Health Care Solutions",
"Health Choice One",
"Health Net",
"HealthMarkets",
"HealthShare American",
"HomeInsurance.Com",
"Humana",
"IMMS/Group 500",
"Infinity Insurance",
"Insphere Insurance Solutions",
"InsuraMatch",
"Insurance Insight",
"Iroquois Group",
"John Hancock",
"Kaiser Permanente",
"Kemper",
"Kemper Direct",
"Liberty National",
"Mass Mutual",
"Matrix Direct",
"Mega / Midwest",
"Messer Financial",
"Metropolitan",
"Midland National Life",
"Mountain Financial",
"Mutual Of Omaha",
"New England Financial",
"Northwestern Mutual Life",
"Oxford Life Insurance",
"PEMCO Insurance",
"Plymouth Rock",
"Primerica",
"Prudential",
"RBC Liberty Insurance",
"Reliance Administrators, Inc.",
"Response",
"SAFECO",
"Senior Market Sales",
"Sentry",
"Shelter",
"Solera Insurance",
"State Auto",
"Sure Health Plans",
"The Hartford",
"Titan",
"Travelers",
"Tri-State Consumer Insurance",
"UniCare",
"United American/Farm and Ranch",
"US Financial",
"US Health Group",
"USA Benefits",
"USAA",
"Western and Southern Life",
"Western Mutual",
"Woodlands Financial Group",
"Zurich Insurance",
];

export { featuredInsuranceCompanies, otherInsuranceCompanies};

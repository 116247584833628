import API from "./api";

export const SAVING_FORM_ENTRY = 'GET_FORM_ENTRY';
export const SAVED_FORM_ENTRY = 'SAVED_FORM_ENTRY';
export const REQUESTING_FORM_ENTRY = 'REQUESTING_FORM_ENTRY';
export const RECEIVED_FORM_ENTRY = 'RECEIVED_FORM_ENTRY';
export const RESET_FORM = 'RESET_FORM';
export const REQUESTING_CITY = 'REQUESTING_CITY';
export const RECEIVED_CITY = 'RECEIVED_CITY';

const requestingCity = () => {
	return {
		type: REQUESTING_CITY
	}
};

const receivedCity = (data) => {
	return {
		type: RECEIVED_CITY,
		city: data.city ? data.city : data.city,
		state: data.state ? data.state : ''
	}
};

const requestingEntry = () => {
	return {
		type: REQUESTING_FORM_ENTRY
	}
};

const receivedEntry = (entryData) => {
	const { id } = entryData;
	const newData = entryData.data ? entryData.data : {};

	if (id) {
		newData['sessionId'] = id;
	}

	return {
		type: RECEIVED_FORM_ENTRY,
		data: newData
	}
};

const savingEntry = () => {
	return {
		type: SAVING_FORM_ENTRY
	}
};

const savedEntry = (entryData) => {
	let data = {};
	let response = {};

	if (entryData.data) {
		data = entryData.data;
		data['sessionId'] = entryData.id
	}

	if (entryData.response) {
		response = entryData.response;
	}

	return {
		type: SAVED_FORM_ENTRY,
		data,
		response
	}
};


function saveFormEntry(formData, currentStep, prevStep, completed=false) {
	return async (dispatch, getState, api) => {
		const state = getState();
		const { sessionId } = state.data.formEntry;
		const dataAPI = new API(api);
		let json = {data:{}};

		formData['currentStep'] = currentStep;
		formData['prevSteps'] = prevStep;

		dispatch(savingEntry());

		if (!sessionId) {
			json = await dataAPI.createFormEntry(formData, completed);
		} else {
			json = await dataAPI.updateFormEntry(sessionId, formData, completed);
		}

		dispatch(savedEntry(json.data));
	}
}

function getFormEntry(id) {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requestingEntry());

		const json = await dataAPI.getFormEntry(id);

		dispatch(receivedEntry(json.data));
	}
}

function getCityFromZip(zipcode) {
	return async (dispatch, getState, api) => {
		const dataAPI = new API(api);

		dispatch(requestingCity);

		const json = await dataAPI.getCityState(zipcode);

		dispatch(receivedCity(json.data));
	}
}

// function resetFormData () {
// 	console.log('here');
// 	return async (dispatch) => {
// 		console.log('a');
// 		dispatch(resetForm());
// 	}
// }
function resetFormData () {
	return {
		type: RESET_FORM
	}
}

export { saveFormEntry, getFormEntry, resetFormData, getCityFromZip };


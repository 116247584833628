import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Loading = () => {
	return (
		<div className="row">
			<div className="loading col-12"><FontAwesomeIcon icon={['far', 'spinner']} spin/></div>
		</div>
	);
};

export default Loading;
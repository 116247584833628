import React, {useEffect, useContext, Fragment} from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';


import EF from 'EF';
import AdditionalProviders from "../AdditionalProviders";
import { SiteContext } from "../../../siteContext";

const Success = props => {
	const {
		price,
		getAdditionalProviders,
		additionalProviders,
		offerId,
		leadId,
		firstName,
		lastName,
		email
	} = props;

	const siteContext = useContext(SiteContext);
	const { publisher } = siteContext;

	useEffect(() => {
		console.log(`Lead price is: ${price}`);
		if (price) {
			EF.conversion({
    			offer_id: offerId ? parseInt(offerId) : 39009,
    			amount: price,
				adv1: firstName,
				adv2: lastName,
				adv3: email,
				adv4: leadId
			});
		}

	}, [price]);

	useEffect(() => {
		// Request additional providers
		getAdditionalProviders();

		ReactPixel.trackCustom('submit_success');
		window.gtag('event', 'conversion', {'send_to': 'AW-788778593/UIZtCJjz-dQBEOGcj_gC'});
		window.gtag('event', 'lead form click', {
		  'event_category': 'Final Page',
		  'event_label': 'Submitted Success'
		});

		if (publisher === 'go') {
			window.gtag('event', 'conversion', {
				'send_to': 'AW-491519115/szxVCKvaiesBEIv5r-oB',
				'value': 5.0,
				'currency': 'USD'
			});
		}

	}, []);

	return (
		<Fragment>
			{ additionalProviders.data.length > 0 &&
				<div className="col-12">
					<AdditionalProviders
						additionalProviders={additionalProviders}
						offerId={offerId}
					/>
				</div>
			}
		</Fragment>
	);
};


export default Success;




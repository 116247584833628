import React, {Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import Failed from './Failed';
import Success from './Success';
import Loading from '../../components/Loading';
import {getAdditionalProviders} from "../../data/dataLot/actions";

const Results = props => {
	const {
		response,
		formEntry,
		_getAdditionalProviders,
		additionalProviders
	} = props;

	// const response = {
	// 	result: "success",
	// 	price: "1.00"
	// }

	useEffect(() => {
		ReactPixel.trackCustom('form_submit');

		window.snaptr('track','PURCHASE');

        window.pintrk('track', 'lead', {
            lead_type: 'form completion'
        });
    }, []);

	const getAdditionalProviders = () => {
		// Fetching additional providers in GetMyQuote (last step)
		// Only fetch if no data in store
		if (additionalProviders.data.length < 1) {
			_getAdditionalProviders(formEntry);
		}
	};

	const renderResponse = () => {
		if (response.result === "success") {
			return (
				<div className="row">
					<div className="question col-12 col-sm-8 mx-auto mt-4 text-center">
					<h4>Your Prepared Quotes</h4>
					<Success
						price={response.price}
						additionalProviders={additionalProviders}
						offerId={formEntry.oid}
						leadId={response.lead_id}
						firstName={formEntry.first_name}
						lastName={formEntry.last_name}
						email={formEntry.email}
						getAdditionalProviders={getAdditionalProviders}
					/>
				</div>
				<div className="col-12 col-sm-8 mx-auto text-center">
				<a href="tel:8449292735"><img src="https://quotegeni.us/assets/images/agent.png" /></a>
				</div>
				</div>

			);
		} else if (response.result === "failed") {
			return (
				<div className="row">
				<h4>Your Prepared Quotes</h4>
				<div className="question col-12 col-sm-8 mx-auto mt-4 text-center">
					<Failed
						additionalProviders={additionalProviders}
						offerId={formEntry.oid}
					/>
				</div>
				</div>
			);
		} else {
			return (
				<Loading />
			);
		}
	}
	return (
		<Fragment>
			{ renderResponse() }
		</Fragment>
	);
};

const mapStateToProps = state => {
	const { formEntry, additionalProviders } = state.data;

	return {
		response: formEntry.response,
		formEntry,
		additionalProviders
	}
}

const mapDispatchToProps = dispatch => {
	return {
		_getAdditionalProviders: formData => dispatch(getAdditionalProviders(formData)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);


import React, { useContext } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { useTracking } from './useTracking';

import { SiteContext } from "./siteContext";

import './FontAwesome';
import Quote from './pages/Quote';
import Results from './pages/Results';

const App = () => {
	const siteContext = useContext(SiteContext);
	const { publisher } = siteContext;

	if (publisher === 'aragon') {
		useTracking('UA-47881347-25');
	} else if (publisher === 'go') {
		useTracking('UA-120512848-1');
	}

	return (
		<Switch>
			<Route path="/results" exact>
				<Results />
			</Route>
			<Route path="/:stepNumber?">
				<Quote />
			</Route>
		</Switch>
	);
}

export default withRouter(App)

import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';


const PolicyDuration = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
	} = props;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-10">
					<h3>How long have you continuously had auto insurance?</h3>
					<div className="row">
						<div className="col">
							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="policy_duration"
								value="11"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								Less than a year
							</ChoiceButton>

							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="policy_duration"
								value="24"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								1 to 2 years
							</ChoiceButton>

							<ChoiceButton
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="policy_duration"
								value="36"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								2 to 3 years
							</ChoiceButton>
							<ChoiceButton 
								values={values}
								className="btn btn-list-select no-width"
								type="submit"
								name="policy_duration"
								value="48"
								setFieldValue={setFieldValue}
								onButtonClick={onButtonClick}
							>
								4+ years
							</ChoiceButton>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default PolicyDuration;
import React from 'react';
import CSSModules from 'react-css-modules';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';
import { featuredInsuranceCompanies, otherInsuranceCompanies } from './insuranceCompanies';
import styles from './styles.scss';


const CurrentCarrier = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
		submitForm
	} = props;

	const onSelectChange = async (event) => {
		const { target: { value } } = event;

		if (value && value !== '-1') {
			// Set the fieldValue
			await setFieldValue('carrier', value);

			// Submit to move on
			submitForm();
		}
	}

	const renderFeaturedCompanies = () => {
		return featuredInsuranceCompanies.map((carrier) => {
			return (
				<ChoiceButton
					key={carrier}
					values={values}
					className="btn btn-list-select no-width"
					name="carrier"
					type="submit"
					value={carrier}
					setFieldValue={setFieldValue}
					onButtonClick={onButtonClick}
				>
					{carrier}
				</ChoiceButton>
			);
		});
	};

	const renderOtherCompanies = () => {
		return otherInsuranceCompanies.map((carrier) => {
			return (
				<option key={carrier}>
					{carrier}
				</option>
			);
		});
	};

	const otherSelected = otherInsuranceCompanies.includes(values['carrier']) 
							? values['carrier']
							: false;

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12">
					<h3>Current Auto Insurance Company</h3>
					<div className="row">
						<div className="col">
							{renderFeaturedCompanies()}
							<select
								styleName="select-other"
								className={otherSelected ? 'selected' : ''}
								value={otherSelected ? otherSelected : '-1'}
								onChange={(event) => onSelectChange(event)}
							>
								<option
									value="-1"
									disabled
								>
									Select Other
								</option>
								{renderOtherCompanies()}
							</select>
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default CSSModules(CurrentCarrier, styles);
import baseApi from '../api';

export default class dataLotAPI extends baseApi {
    async getAdditionalProviders(formData) {
        const data = {
            action: 'getAdditionalProviders',
            formData
        };

        const response = await this.fetchFromAPI(
            '',
            'get-additional-providers',
            'Get additional providers',
            'post',
            data
        );

        return response;
    }
}

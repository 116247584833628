import React from 'react';
import { Field } from "formik";


const BootstrapField = (props) => {
	const {
		hasErrors,
		className,
		...rest
	} = props;

	const bootstrapClass = hasErrors ? `${className} is-invalid` : className;

	return (
		<Field {...rest} className={bootstrapClass} />
	)
};

export default BootstrapField;
import React, {useEffect} from 'react';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import * as Yup from "yup";
import {connect} from 'react-redux';

import WizardStep from '../WizardStep';
import BootstrapField from '../BootstrapField';
import PhoneField from '../PhoneField';
import {getCityFromZip} from '../../data/formEntry/actions';
import {getAdditionalProviders} from "../../data/dataLot/actions";

const GetMyQuote = props => {
    const {
        setValidationSchema,
        getFieldError,
        setFieldValue,
        touched,
        errors,
        formEntry,
        getCity,
        jornayaRef,
        getAdditionalProviders,
        values
    } = props;

    const validationSchema = Yup.object({
        first_name: Yup.string().required('First Name is required.'),
        last_name: Yup.string().required('Last Name is required.'),
        email: Yup.string()
            .email('A valid Email Address is required.')
            .required('A valid Email Address is required.'),
        address: Yup.string().required('Street Address is required.'),
        phone: Yup.string().required('A valid Phone Number is required.')
            .test(
                'is-phone-number',
                'A valid Phone Number is required.',
                (value) => isPossiblePhoneNumber(value)
            )
    });

    useEffect(() => {
        setValidationSchema(validationSchema);
        getCity(formEntry['zip_code']);
    }, []);


    useEffect(() => {
        const setCityState = async () => {
            // Set State and City on change
            await Promise.all([
                setFieldValue('state', formEntry.state),
                setFieldValue('city', formEntry.city)
            ]);

            getAdditionalProviders(values);
        }

        setCityState();

    }, [formEntry.state]);

    const onClick = async () => {
        // console.log(jornayaRef.current.value);
        await setFieldValue('jornaya_lead_id', jornayaRef.current.value);
    };

    return (
        <WizardStep>
            <div className="question row">
                <div className="col-12 col-md-8 col-lg-6">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb-4">
                            <h3>First Name</h3>
                            <div className="input-group">
                                <BootstrapField
                                    type="text"
                                    className="form-control"
                                    name="first_name"
                                    hasErrors={getFieldError('first_name')}
                                />
                                <div className="invalid-feedback">{getFieldError('first_name')}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                            <h3>Last Name</h3>
                            <div className="input-group">
                                <BootstrapField
                                    type="text"
                                    className="form-control"
                                    name="last_name"
                                    hasErrors={getFieldError('last_name')}
                                />
                                <div className="invalid-feedback">{getFieldError('last_name')}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                            <h3>Email Address</h3>
                            <div className="input-group">
                                <BootstrapField
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    hasErrors={getFieldError('email')}
                                />
                                <div className="invalid-feedback">{getFieldError('email')}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                            <h3>Phone Number</h3>
                            <div className="input-group">
                                <BootstrapField
                                    className="form-control"
                                    name="phone"
                                    hasErrors={getFieldError('phone')}
                                    component={PhoneField}
                                />
                                {errors['phone'] && touched['phone']
                                && <div className="invalid-feedback show">A valid Phone Number is required.</div>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                            <h3>Street Address</h3>
                            <div className="input-group">
                                <BootstrapField
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    hasErrors={getFieldError('address')}
                                />
                                <div className="invalid-feedback">Street Address is required.</div>
                            </div>
                            <br/>
                            <h6 className="text-info">
                                {formEntry.city}, {formEntry.state} {formEntry.zip_code}
                            </h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mt-3">
                            <button
                                onClick={() => onClick()}
                                type="submit"
                                className="btn btn-success btn-lg"
                            >
                                Get My Quotes
                            </button>
                            <div className="disclosure small mt-4">
                                <label><input type="hidden" id="leadid_tcpa_disclosure"/>
                                    <p>
                                        By clicking "Get My Quotes" above, I agree that I am 18+ years of age and I
                                        provide my electronic signature and express written consent to marketing
                                        communications via telemarketing calls, autodialed and/or pre-recorded calls,
                                        artificial voice messages, text messages (SMS/MMS), emails, and postal mail from
                                        this Web site and our <a
                                        href="https://quotegeni.us/marketing-partners/" target="_blank"
                                        rel="noreferrer">marketing partners</a>, at the phone number
                                        (including wireless number), email address, and postal address provided by me,
                                        including calls and text messages transmitting insurance quotes, or seeking
                                        related additional information from me, using an Automatic Telephone Dialing
                                        System or prerecorded or artificial voices, even if I have previously registered
                                        the provided number on any federal, state, or company do not call registry. I
                                        understand that my consent is not a condition of purchasing any property,
                                        products, goods, or services and that I may revoke my consent at any time.
                                        Carrier SMS/MMS and data messaging rates may apply.
                                    </p>
                                    <p>
                                        Additionally, by clicking "Get My Quotes" above, I acknowledge that I have read,
                                        understand, and agree to this Web site’s <a href="https://quotegeni.us/privacy-policy/"
                                                                                    target="_blank" rel="noreferrer">Privacy
                                        Policy</a> and <a href="https://quotegeni.us/terms-of-use/" target="_blank"
                                                          rel="noreferrer">Terms of Use</a>.
                                    </p>
                                    <p>
                                        I am directing this Web site to share my information to the providers in our
                                        network for the purpose of providing me with information about their insurance
                                        and financial services and products.
                                    </p>

                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WizardStep>
    );
};

const mapStateToProps = state => {
    const {formEntry} = state.data;

    return {
        formEntry
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCity: (zipcode) => dispatch(getCityFromZip(zipcode)),
        getAdditionalProviders: formData => dispatch(getAdditionalProviders(formData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetMyQuote);

import { combineReducers } from 'redux';

import { reducer as formEntryReducer } from './formEntry/reducer';
import { reducer as vehiclesReducer } from './vehicles/reducer';
import { reducer as dataLotReducer } from './dataLot/reducer';

export default combineReducers({
	formEntry: formEntryReducer,
	vehicles: vehiclesReducer,
	additionalProviders: dataLotReducer
});

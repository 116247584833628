import {
	REQUEST_VEHICLE_MODELS,
	RECEIVE_VEHICLE_MODELS
} from './actions';

const initialState = {
	requesting: false,
	loadedModels: []
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_VEHICLE_MODELS:
			return Object.assign({}, state, {
				requesting: true,
				loadedModels: []
			});
		case RECEIVE_VEHICLE_MODELS:
			return Object.assign({}, state, {
				requesting: false,
				loadedModels: action.models
			});
		default:
			return state;
	}
};

import API from "./api";

export const REQUEST_ADDITIONAL_PROVIDERS = 'REQUEST_ADDITIONAL_PROVIDERS';
export const RECEIVE_ADDITIONAL_PROVIDERS = 'RECEIVE_ADDITIONAL_PROVIDERS';


const requesting = () => {
    return {
        type: REQUEST_ADDITIONAL_PROVIDERS
    };
}

const receive = (data) => {
    return {
        type: RECEIVE_ADDITIONAL_PROVIDERS,
        data
    };
}

const getAdditionalProviders = (formData) => {
    return async (dispatch, getState, api) => {
        const dataAPI = new API(api);

        dispatch(requesting());

        const json = await dataAPI.getAdditionalProviders(formData);

        dispatch(receive(json.data));
    }
};

export { getAdditionalProviders };


import React, {useEffect, useContext, Fragment} from 'react';
import ReactPixel from 'react-facebook-pixel';
import AdditionalProviders from "../AdditionalProviders";
import {SiteContext} from "../../../siteContext";
// import ReactGA from 'react-ga';


const Failed = props => {
	const { additionalProviders, offerId } = props;

	// const siteContext = useContext(SiteContext);
	// const { publisher } = siteContext;

	useEffect(() => {

		console.log(`Lead price is: 0`);
		ReactPixel.trackCustom('submit_fail');
		window.gtag('event', 'conversion', {'send_to': 'AW-788778593/UIZtCJjz-dQBEOGcj_gC'});
		window.gtag('event', 'lead form click', {
		  'event_category': 'Final Page',
		  'event_label': 'Submitted Failed'
		});
	}, []);

	return (
		<Fragment>
			{ additionalProviders.data.length > 0 &&
			<div className="col-12">
				<AdditionalProviders
					additionalProviders={additionalProviders}
					offerId={offerId}
				/>
			</div>
			}
		</Fragment>
	);
};

export default Failed;

import React from 'react';

import WizardStep from '../WizardStep';
import ChoiceButton from '../ChoiceButton';

const SelectVehicleYear = props => {
	const {
		setFieldValue,
		vehicleNumber,
		values,
		onButtonClick
	} = props;

	const renderYears = () => {
		const years = [];

		for ( let i = 0; i < 40; i++) {
			const year = 2021 - i;

			years.push(
				<ChoiceButton
					key={i}
					values={values}
					className="btn btn-list-select"
					name={`vehicle_${vehicleNumber}_year`}
					value={year}
					setFieldValue={setFieldValue}
					onButtonClick={onButtonClick}
				>
					{year}
				</ChoiceButton>
			);
		}

		return years;
	}

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12 col-lg-8">
					<h3>Select Your Vehicle</h3>
					<label htmlFor="vehicleYear">Year</label>
					<div className="row">
						<div className="col">
							{renderYears()}
						</div>
					</div>
				</div>
			</div>
		</WizardStep>
	);
};

export default SelectVehicleYear;
import React from 'react';
import { Field } from 'formik';

import WizardStep from '../WizardStep';


const Record = props => {
	const {
		setFieldValue,
		values,
		onButtonClick,
	} = props;

	const onClick = (field, value) => {
		setFieldValue(field, value);
		// onButtonClick(field, value);
	}

	const onContinue = () => {
		const fields = [
			'suspension',
			'ticket',
			'violation',
			'accident',
			'claim',
			'sr22'
		];

		fields.forEach(field => {
			const value = values[field];

			window.gtag('event', 'lead form click', {
		  		'event_category': field,
		  		'event_label': value
			});
		});
	}

	return (
		<WizardStep>
			<div className="question row">
				<div className="col-12">
					<h3>Has anyone on this policy had:</h3>

					<div className="row">
						<div className="col-12 col-md-8 col-lg-6">
							<label>A license suspension in the past five (5) years?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="suspension"
								checked={String(values.suspension) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('suspension', 'true')}
							>
								Yes</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="suspension"
								checked={String(values.suspension) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('suspension', 'false')}
							>
								No</label>
						</div>
					</div>

					<div className="row">
						<hr className="hrule" />
						<div className="col-12 col-md-8 col-lg-6">
							<label>Two (2) or more tickets in the past three (3) years?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="ticket"
								checked={String(values.ticket) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('ticket', 'true')}
							>
								Yes
							</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="ticket"
								checked={String(values.ticket) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('ticket', 'false')}
							>
								No</label>
						</div>
					</div>

					<div className="row">
						<hr className="hrule" />
						<div className="col-12 col-md-8 col-lg-6">
							<label>A major violation in the past seven (7) years?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="violation"
								checked={String(values.violation) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('violation', 'true')}
							>
								Yes
							</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="violation"
								checked={String(values.violation) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('violation', 'false')}
							>
								No</label>
						</div>
					</div>

					<div className="row">
						<hr className="hrule" />
						<div className="col-12 col-md-8 col-lg-6">
							<label>An at-fault accident in the past three (3) years?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="accident"
								checked={String(values.accident) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('accident', 'true')}
							>
								Yes</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="accident"
								checked={String(values.accident) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('accident', 'false')}
							>
								No</label>
						</div>
					</div>

					<div className="row">
						<hr className="hrule" />
						<div className="col-12 col-md-8 col-lg-6">
							<label>Made a claim in the past three (3) years?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="claim"
								checked={String(values.claim) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('claim', 'true')}
							>
								Yes</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="claim"
								checked={String(values.claim) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('claim', 'false')}
							>
								No</label>
						</div>
					</div>

					<div className="row">
						<hr className="hrule" />
						<div className="col-12 col-md-8 col-lg-6">
							<label>Do you require an SR-22 or SR-1?</label><br/>
							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="sr22"
								checked={String(values.sr22) === 'true'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('sr22', 'true')}
							>
								Yes</label>

							<Field
								className="form-check-input radio accidents"
								type="radio"
								name="sr22"
								checked={String(values.sr22) === 'false'}
							/>
							<label
								className="form-check-label radio-label"
								onClick={() => onClick('sr22', 'false')}
							>
								No</label>
						</div>
					</div>

						<button onClick={() => onContinue()} type="submit" className="btn btn-success">Continue</button>
				</div>
			</div>
		</WizardStep>
	);
};

export default Record;
import {
	SAVING_FORM_ENTRY,
	SAVED_FORM_ENTRY,
	REQUESTING_FORM_ENTRY,
	RECEIVED_FORM_ENTRY,
	RESET_FORM,
	REQUESTING_CITY,
	RECEIVED_CITY
} from './actions';

const initialState = {
	complete: false,
	oid: '',
	affid: '',
	lp_s1: '',
	lp_s2: '',
	lp_s3: '',
	lp_s4: '',
	lp_s5: '',
	sending: false,
	sessionId: '',
	currentStep: 0,
	prevSteps: [0, ],
	incrementStep: 1,
	lp_campaign_id: '',
	lp_campaign_key: '',
	first_name: '',
	last_name: '',
	dob: {day: '', month: '', year: ''},
	drivers_age: '',
	address: '',
	zip_code: '',
	city: '',
	state: '',
	county: '',
	gender: '',
	married: '',
	credit_score: '',
	residence: '',
	phone: '',
	email: '',
	vehicle_1_make: '',
	vehicle_1_year: '',
	vehicle_1_model: '',
	// add_second_vehicle: false,
	// vehicle_2_make: '',
	// vehicle_2_year: '',
	// vehicle_2_model: '',
	owner: '',
	DL_status: '',
	garage: '',
	primary_use: '',
	one_way_mileage: '',
	annual_mileage: '',
	sr22: 'false',
	accident: 'false',
	suspension: 'false',
	violation: 'false',
	claim: 'false',
	ticket: 'false',
	currently_insured: '',
	carrier: '',
	jornaya_lead_id: '',
	policy_duration: '',
	ip_address: '',
	response: {
		result: "",
		lead_id: "",
		price: ""
	}
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVING_FORM_ENTRY:
		case REQUESTING_FORM_ENTRY:
		case REQUESTING_CITY:
			return Object.assign({}, state, {
				...state,
				sending: true,
			});
		case SAVED_FORM_ENTRY:
		case RECEIVED_FORM_ENTRY:
			return Object.assign({}, state, {
				sending: false,
				response: action.response,
				...action.data
			});
		case RESET_FORM:
			return initialState;
		case RECEIVED_CITY:
			return Object.assign({}, state, {
				...state,
				sending: false,
				city: action.city,
				state: action.state
			});
		default:
			return state;
	}
};

import React from 'react';
import PropTypes from 'prop-types';

import getPropertyValue from '../../utils/getPropertyValue';

const ChoiceButton = (props) => {
	const {
		values,
		className,
		name,
		value,
		setFieldValue,
		children,
		tag,
		onButtonClick,
		onClick,
		...rest
	} = props;

	const buttonClassName = getPropertyValue(values, name) === value ? `${className} selected` : className;

	const localOnClick = (value) => {
		onButtonClick(name, value);
		setFieldValue(name, value);

		if (onClick) {
			onClick();
		}
	}

	const CustomTag = tag;

	return (
		<CustomTag
			{...rest}
			onClick={() => localOnClick(value)}
			className={buttonClassName}
		>
			{children}
		</CustomTag>
	);
};

ChoiceButton.propTypes = {
	type: PropTypes.string,
	values: PropTypes.object.isRequired,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	setFieldValue: PropTypes.func.isRequired
};

ChoiceButton.defaultProps = {
	tag: 'button',
}

export default ChoiceButton;